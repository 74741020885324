import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const br = <br></br>;

  return (
    <main className="Privacy">
      <div className="Privacy-Container">
        <h2>Privacy Policy</h2>
        <h4>Last Updated: 09/24/2023</h4>
        <p>
          {br} At Post-Plant, we take your privacy seriously and are committed
          to safeguarding your information. This Privacy Policy explains how we
          collect, use, and protect your data when you use our services.
        </p>

        <h1>{br}Information We Collect</h1>
        <p>
          We collect certain information to provide our services effectively.
          The personal information we collect depends on the context of your
          interactions with us the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include:
        </p>
        <p>
          <ul>
            <li>Usernames</li>
            <li>OpenID/OAuth ID</li>
            <li>Riot ID</li>
          </ul>
        </p>

        <h1>{br}How We Use Your Information</h1>
        <p>
          We use your data for the following purposes: {br}
          <ul>
            <li>
              Riot ID/Username: We use your Riot ID to gather data and statics
              from Riot Games' official API.
            </li>
          </ul>
        </p>

        <h1>{br}Data Sharing</h1>
        <p>
          At Post-Plant, we take data protection seriously, and we do not share
          your information with any third parties. Your data is kept
          confidential and used solely for the purposes outlined in this Privacy
          Policy. We are committed to safeguarding your privacy and ensuring
          that your data remains secure.
        </p>

        <h1>{br}Cookies</h1>
        <p>
          Like any other website, Post-Plant uses "cookies". These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information. {br}
          {br} You can configure your browser to reject our cookies, but some
          features may not work correctly without them.
        </p>

        <h1>{br}Children's Information</h1>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
          {br}
          {br}Post-Plant does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>

        <h1>{br}California Residents</h1>
        <p>
          The CCPA provides California residents with specific rights regarding
          their personal information:
        </p>
        <ul>
          <li>
            The right to know which personal information is being collected
          </li>
          <li>
            The right to know if personal data is being sold or shared, and to
            whom
          </li>
          <li>The right to object to the sale of personal data</li>
          <li>The right to access one's own personal information</li>
          <li>
            The right to equal service and price, even for consumers who
            exercise their privacy rights
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us through the methods listed
          below.
        </p>

        <h1>{br}Privacy Policy Changes</h1>
        <p>
          We may update this policy occasionally. We won't reduce your rights
          without your explicit consent. Any significant changes will be
          prominently notified.
        </p>

        <h1>{br}Contact Us</h1>
        <p>
          If you have any questions or concerns about your privacy, please reach
          out to us at postplantapp@gmail.com
        </p>

        <h1>{br}Consent</h1>
        <p>
          Post-Plant was created under Riot Games' "Legal Jibber Jabber" policy
          using assets owned by Riot Games. Riot Games does not endorse or
          sponsor this project.
          {br}
          {br}
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
