import React, { useState, useEffect } from "react";
import ranks from "../../assets/templates/compTiers.json";

import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(...registerables, annotationPlugin);

const RankHistory = ({ stats }) => {
  const [annotations, setAnnotations] = useState([]);

  const loadImage = (src) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.src = src;
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      const loadedAnnotations = [];

      for (let i = 0; i < stats.length; i += 3) {
        const item = stats[i];
        const image = await loadImage(ranks[item.tier]["smallIcon"]);
        loadedAnnotations.push({
          type: "label",
          drawTime: "afterDraw",
          content: image,
          width: 40,
          height: 40,
          xValue: new Date(item.date_raw * 1000),
          yValue: item.elo + 50,
        });
      }

      setAnnotations(loadedAnnotations);
    };

    loadImages();
  }, [stats]);

  const dataPoints = stats.map((item) => {
    return {
      x: new Date(item.date_raw * 1000), // Converting UNIX timestamp to JavaScript Date
      y: item.elo,
    };
  });

  const data = {
    datasets: [
      {
        label: "Elo",
        data: dataPoints,
        borderColor: "#d30011",
        fill: false,
        cubicInterpolationMode: "monotone",
        tension: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Elo History",
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const rank = stats[index];
            return `${rank.name}: ${rank.ranking_in_tier} RR`;
          },
        },
      },
      annotation: {
        annotations: annotations,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        beginAtZero: false,
      },
    },
  };

  return (
    <section className="RankHistory">
      <Line data={data} options={options} />
    </section>
  );
};

export default RankHistory;
