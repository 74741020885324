import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useUser } from "../features/auth/UserContext";
import { fetchWithoutToken } from "../features/api/api";

const Callback = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (user) {
      navigate("/dashboard", { replace: true });
      return;
    }

    const fetchJWT = async () => {
      const codeParam = searchParams.get("code");
      if (!codeParam) {
        navigate("/login", { replace: true });
        return;
      }

      try {
        const response = await fetchWithoutToken(
          `/oauth/callback?code=${codeParam}`
        );
        if (!response) throw new Error("Failed to authenticate");

        localStorage.setItem("post-plant-token", response.token);
        setUser(response.user);
        navigate("/dashboard", { replace: true });
      } catch (err) {
        console.error("Authentication error:", err);
        navigate("/login", { replace: true });
      }
    };

    fetchJWT();
  }, []);

  return null;
};

export default Callback;
