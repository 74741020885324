import React from "react";
import "./BestAgent.css";

import agents from "../../assets/templates/agents.json";

const BestAgent = ({ stats }) => {
  const colors = {
    good: "#30c464",
    avg: "#ffc454",
    bad: "#ff4f4f",
  };

  const round = (number) => {
    return Math.round(number * 100.0) / 100.0;
  };

  return (
    <div className="BestAgent">
      <div className="AgentImage">
        <h4>{stats ? agents[stats.agentId].displayName : "null"}</h4>
        <img
          src={stats ? agents[stats.agentId].portrait : null}
          alt="Full view illustration of a VALORANT agent"
          width={205}
          height={186}
          style={{
            transform: "scaleX(-1)",
            width: 160,
            height: 300,
            objectFit: "cover",
          }}
        />
      </div>

      <div className="AgentStats">
        <li>
          <div className="Stat">
            Win %:{" "}
            <span
              className="StatsScore"
              style={{
                color: stats.winPercentage > 0.5 ? colors.good : colors.avg,
              }}
            >
              {round(stats.winPercentage * 100)}%
            </span>
          </div>
        </li>

        <li>
          <div className="Stat">
            K/D:{" "}
            <span
              className="StatsScore"
              style={{ color: stats.kd > 0.99 ? colors.good : colors.avg }}
            >
              {round(stats.kd)}
            </span>
          </div>
        </li>

        <li>
          <div className="Stat">
            ACS:{" "}
            <span
              className="StatsScore"
              style={{ color: stats.acs > 250 ? colors.good : colors.avg }}
            >
              {round(stats.acs)}
            </span>
          </div>
        </li>
      </div>
    </div>
  );
};

export default BestAgent;
