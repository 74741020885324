import React from "react";
import { NavLink } from "react-router-dom";
import PostPlantLogo from "../../assets/logos/post-plant-logo.png";
import "./Logo.css";

const Logo = () => {
  return (
    <NavLink to="/dashboard" className="LogoContainer">
      <section className="Logo">
        <img src={PostPlantLogo} alt="Post-Plant logo" width={30} height={28} />
        <h1>Post-Plant</h1>
      </section>
    </NavLink>
  );
};

export default Logo;
