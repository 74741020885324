import React from "react";
import "./Clutches.css";

import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";
Chart.register(...registerables);

const Clutches = ({ stats }) => {
  const clutchLabels = [];
  const clutchSuccessRates = [];
  const clutchAttempts = [];
  const clutchSuccesses = [];

  for (const [key, value] of Object.entries(stats)) {
    if (key.includes("v")) {
      // Check if key is a clutch scenario
      clutchLabels.push(key);
      clutchAttempts.push(value.attempts);
      clutchSuccesses.push(value.successes);
      const successRate =
        value.attempts > 0 ? (value.successes / value.attempts) * 100 : 0;
      clutchSuccessRates.push(successRate);
    }
  }

  const data = {
    labels: clutchLabels,
    datasets: [
      {
        label: "Clutch Success Rate (%)",
        data: clutchSuccessRates,
        fill: false,
        backgroundColor: "rgba(211, 0, 17, 0.4)",
        borderColor: "rgba(211, 0, 17, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Clutch Successes",
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (context) {
            const index = context.dataIndex;
            const rate = clutchSuccessRates[index];
            const successes = clutchSuccesses[index];
            const attempts = clutchAttempts[index];
            return `${rate.toFixed(0)}% (${successes}/${attempts} won)`;
          },
        },
      },
    },

    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Success Rate (%)",
        },
      },
    },
  };

  const dataDonut = {
    labels: ["Wins", "Losses"],
    datasets: [
      {
        label: "Games",
        data: [
          stats.clutchSuccesses,
          stats.clutchAttempts - stats.clutchSuccesses,
        ],
        backgroundColor: ["#30c464", "#404146"],
        borderColor: "rgba(255, 255, 255, 0)",
        borderWidth: 1,
      },
    ],
  };

  const optionsDonut = {
    responsive: true,
    cutout: "70%", // Adjust as needed
    circumference: 180, // Half circle
    rotation: -90, // Start the half circle from the top
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: {
        display: false, // Disable legend if you want a cleaner look
      },
      datalabels: {
        display: false, // Disable data labels on the chart
      },
    },
  };

  return (
    <div className="ClutchesPerformance">
      <div className="MainStats">
        <div className="DonutContainer">
          <Doughnut data={dataDonut} options={optionsDonut} />
        </div>
        <h1>{(stats.clutchPercentage * 100).toFixed(2)}%</h1>

        <div className="ClutchStat">
          <h2>{stats.clutchSuccesses} total clutches won</h2>
          <p>{stats.clutchAttempts} clutch attempts</p>
        </div>
      </div>

      <div className="BarContainer">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default Clutches;
