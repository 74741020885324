import React, { useState } from "react";
import "./SelectVOD.css";

import AddVOD from "./AddVOD";
import VODStartTime from "./VODStartTime";
import VODItem from "./VODItem";

const SelectVOD = ({ vodLinks, setSelectedVOD }) => {
  const [isValid, setIsValid] = useState(null);
  const [newVODLink, setNewVODLink] = useState("");

  return (
    <div className="SelectVOD">
      {isValid ? (
        <VODStartTime
          newVODLink={newVODLink}
          setNewVODLink={setNewVODLink}
          setIsValid={setIsValid}
          setSelectedVOD={setSelectedVOD}
        />
      ) : (
        <>
          <div className="VODsContainer">
            {vodLinks.length > 0 ? (
              vodLinks.map((link) => (
                <VODItem
                  link={link}
                  setIsValid={setIsValid}
                  setNewVODLink={setNewVODLink}
                  setSelectedVOD={setSelectedVOD}
                  key={link.url}
                />
              ))
            ) : (
              <h1>No VODs found! Add yours below</h1>
            )}
          </div>
          <AddVOD
            isValid={isValid}
            setIsValid={setIsValid}
            setNewVODLink={setNewVODLink}
          />
        </>
      )}
    </div>
  );
};

export default SelectVOD;
