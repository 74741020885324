import React from "react";
import "./Trades.css";

const Trades = ({ stats, totalMatches, totalDeaths, totalKills }) => {
  return (
    <div className="Trades">
      <div className="TradeStat">
        <div className="TradeWord">
          <h1>Was Traded</h1>
          <p>When you die, how often does someone trade you</p>
        </div>
        <h2>{((stats.wasTraded / totalDeaths) * 100).toFixed(2)} %</h2>
      </div>

      <div className="TradeStat">
        <div className="TradeWord">
          <h1>Did Trade</h1>
          <p>When you killed, how often was it a trade on a teammate</p>
        </div>
        <h2>{((stats.tradedTeammate / totalKills) * 100).toFixed(2)} %</h2>
      </div>

      <div className="TradeStat">
        <div className="TradeWord">
          <h1>Overall</h1>
          <p>Trades per match you were involved in</p>
        </div>

        <h2>
          {((stats.wasTraded + stats.tradedTeammate) / totalMatches).toFixed(2)}
        </h2>
      </div>
    </div>
  );
};

export default Trades;
