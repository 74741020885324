import React from "react";

const ShortenedName = ({ name, maxLength }) => {
  if (name.length <= maxLength) {
    return <p>{name}</p>;
  }

  const shortenedName = name.substring(0, maxLength) + "...";

  return <p title={name}>{shortenedName}</p>;
};

export default ShortenedName;
