import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./VODStartTime.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

import { fetchWithToken } from "../../../../features/api/api";

import YouTube from "react-youtube";

import badExample from "../../../../assets/selecttime/bad_settime_ex.png";
import goodExample from "../../../../assets/selecttime/good_settime_ex.png";

const VODStartTime = ({
  newVODLink,
  setNewVODLink,
  setIsValid,
  setSelectedVOD,
}) => {
  const videoRef = useRef(null);
  const { matchId } = useParams();
  const [currentTime, setCurrentTime] = useState(0);

  const formatTime = (totalSeconds) => {
    // Separate seconds and milliseconds
    const wholeSeconds = Math.floor(totalSeconds);
    const milliseconds = Math.round((totalSeconds - wholeSeconds) * 100);

    // Calculate hours, minutes and seconds
    const hours = Math.floor(wholeSeconds / 3600);
    const minutes = Math.floor((wholeSeconds % 3600) / 60);
    const seconds = wholeSeconds % 60;

    // Pad hours, minutes, seconds, and milliseconds with leading zeros, if required
    const paddedHours = String(hours).padStart(1, "0");
    const paddedMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(seconds).padStart(2, "0");
    const paddedMilliseconds = String(milliseconds).padStart(1, "0");

    // Format the time
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}:${paddedMilliseconds}`;
  };

  const getIdFromUrl = (url) => {
    const match = url.match(
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]{11})/
    );
    if (match) {
      return match[1];
    } else {
      return null;
    }
  };

  const removeVOD = () => {
    setNewVODLink("");
    setIsValid(null);
  };

  const setVODTime = async () => {
    const vodData = {
      matchId,
      startTime: currentTime,
      url: newVODLink,
    };
    await fetchWithToken("/matches/replay", "POST", vodData);

    setSelectedVOD(vodData);
  };

  useEffect(() => {
    // Start the interval to update currentTime
    const intervalId = setInterval(() => {
      if (videoRef.current) {
        videoRef.current.internalPlayer.getCurrentTime().then((time) => {
          setCurrentTime(time);
        });
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      {newVODLink ? (
        <section className="VODStartTime">
          <div className="StartTimeLeftSide">
            <div className="VideoVODContainer">
              <YouTube videoId={getIdFromUrl(newVODLink)} ref={videoRef} />
            </div>

            <div className="VideoUnderneathInfo">
              <div className="ConfirmationStartTime">
                <h2>Start Time:</h2>
                <h2>{formatTime(currentTime)}</h2>
              </div>

              <div className="StartTimeButtons">
                <div className="GoBack">
                  <button onClick={removeVOD}>
                    <IoMdArrowRoundBack />
                    <h3>Choose Different VOD</h3>
                  </button>
                </div>

                <div className="SetStartTime">
                  <button onClick={setVODTime}>
                    <h3>Set Start Time</h3>
                    <FaCheck />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="StartTimeRightSide">
            <div className="StartTimeRightSection" style={{ marginTop: 10 }}>
              <h1>How To Set Start Time</h1>
              <p>
                The start time should be the first millisecond of the first
                round. Scrub through the video until you get to the first
                appearance of 1:40 of the first round (0:0).
              </p>
              <h4>Use "," and "." for fine scrubbing</h4>
            </div>

            <div className="StartTimeRightSection">
              <h2 style={{ color: "#ff4f4f" }}>Bad Example</h2>
              <img src={badExample} alt="Bad example" height={150} />
              <p>Still shows 0:00 and buy phase alert.</p>
            </div>

            <div className="StartTimeRightSection">
              <h2 style={{ color: "#30c464" }}>Good Example</h2>
              <img src={goodExample} alt="Bad example" height={150} />
              <p>Shows 1:40 and no buy phase alert.</p>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default VODStartTime;
