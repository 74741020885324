import React from "react";
import "./Frontpage.css";

import { Navigate } from "react-router-dom";
import { useUser } from "../../features/auth/UserContext";
import { Link } from "react-router-dom";

import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaRegCopyright } from "react-icons/fa";

import backgroundImage from "../../assets/maps/AscentBackgroundDarkened.png";
import replayImage from "../../assets/frontpage/frontpage-replay.png";
import scoreboardImage from "../../assets/frontpage/frontpage-scoreboard.png";
import performanceImage from "../../assets/frontpage/frontpage-performance.png";

import CallButton from "../../components/UI/CallButton";
import LoginLoading from "../../components/LoginLoading";
import Logo from "../../components/UI/Logo";

const Frontpage = () => {
  const { user, isAuthenticating } = useUser();

  if (isAuthenticating) {
    return <LoginLoading />;
  }

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    !user && (
      <main className="Frontpage">
        <div className="ImageContainer">
          <img src={backgroundImage} alt="Ascent Background" />
        </div>

        <header className="FrontpageHeading">
          <h1>Post-Plant</h1>
          <p>
            Optimize your VALORANT analysis with a streamlined VOD replay
            experience, offering insightful analytics and detailed breakdowns
            for every play.
          </p>

          <CallButton text={"Try It Now!"} linkTo={"/login"} />

          {/* <div className="MouseScrollDown"></div> */}
        </header>

        <section className="FeaturedInfo">
          <div className="InfoWords">
            <header className="KeyHeader">
              <h3>VOD Replay</h3>
            </header>

            <h1>Analyze your match</h1>
            <p>
              Rewatch your VOD with intuitive round-by-round navigation in order
              to quickly analyze key rounds and find your highlights!
            </p>

            {/* analyze key moments */}

            <CallButton text={"Try It Today!"} linkTo={"/login"} />
          </div>

          <div className="InfoImage">
            <img src={replayImage} alt="Replay System" />
          </div>
        </section>

        <section className="FeaturedInfo">
          <div className="InfoImage" style={{ marginLeft: "100px" }}>
            <img src={scoreboardImage} alt="Ascent Background" />
          </div>

          <div className="InfoWords">
            <header className="KeyHeader">
              <h3>Scoreboard</h3>
            </header>

            <h1 style={{ width: "420px" }}>Post-game match overview</h1>
            <p>
              Find out who had the better stats, and also who maybe threw your
              game...
            </p>

            <CallButton text={"Try It Today!"} linkTo={"/login"} />
          </div>
        </section>

        <section className="FeaturedInfo">
          <div className="InfoWords">
            <header className="KeyHeader">
              <h3>Performance</h3>
            </header>

            <h1 style={{ width: "420px" }}>Track your performance</h1>
            <p>
              Monitor your gameplay with detailed stat tracking. Quickly gauge
              your improvements and pinpoint areas for growth. Watch your
              progress unfold, one post-plant at a time!
            </p>

            <CallButton text={"Try It Today!"} linkTo={"/login"} />
          </div>

          <div className="InfoImage">
            <img src={performanceImage} alt="Ascent Background" />
          </div>
        </section>

        <footer className="FrontpageFooter">
          <div className="FooterLogo">
            <Logo />

            <div className="FooterLinks">
              <a
                href="https://twitter.com/PostPlantApp"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size={20} />
              </a>

              <a href="https://discord.com" target="_blank" rel="noreferrer">
                <FaDiscord size={20} />
              </a>

              <a
                href="mailto:postplantapp@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <MdEmail size={20} />
              </a>
            </div>
          </div>

          <div className="FooterCopywrite">
            <FaRegCopyright />
            <h4>2024 Post-Plant</h4>|
            <div className="FooterTermsLinks">
              <Link to="/privacy-policy">
                <p>Privacy Policy</p>
              </Link>
              <Link to="/terms-of-service">
                <p>Terms of Service</p>
              </Link>
            </div>
          </div>
        </footer>
      </main>
    )
  );
};

export default Frontpage;
