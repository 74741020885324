import React from "react";
import "./ScoreboardTeam.css";

import ScoreboardPlayer from "./ScoreboardPlayer";

const ScoreboardTeam = ({ team, match }) => {
  const details = [
    "Rank",
    "ACS",
    "K",
    "D",
    "A",
    "K/D",
    "ADR",
    "HS%",
    "KAST%",
    "TK",
    "TD",
    "FK",
    "FD",
    "Clutches",
    "Casts",
  ];

  const getPlayerACS = (playerId) => {
    const playerStats = match["players"].find(
      (player) => player.puuid === playerId
    )["stats"];
    return playerStats["score"] / playerStats["roundsPlayed"];
  };

  const sortedPlayers = [...team["players"]].sort(
    (a, b) => getPlayerACS(b) - getPlayerACS(a)
  );

  return (
    <div className="ScoreboardTeam">
      <section
        className="Details"
        style={{
          backgroundColor:
            team["teamId"] === "Red"
              ? "rgba(255, 70, 85, .5)"
              : "rgba(34, 255, 197, .5)",
        }}
      >
        <div className="TeamName">Team {team["teamId"]}</div>
        {details.map((detail) => (
          <div className="Detail" key={detail}>
            {detail}
          </div>
        ))}
      </section>

      <section className="Players">
        {sortedPlayers.map((playerId) => (
          <ScoreboardPlayer
            key={playerId}
            player={match["players"].find(
              (player) => player.puuid === playerId
            )}
          />
        ))}
      </section>
    </div>
  );
};

export default ScoreboardTeam;
