import React from "react";

import ReplayPlayer from "./ReplayPlayer";

const ReplayTeam = ({ team, match, selectedRound }) => {
  const round = match["rounds"][selectedRound];
  const isRedTeam = team["teamId"] === "Red";
  const teamColor = isRedTeam
    ? "rgba(255, 70, 85, 1)"
    : "rgba(34, 255, 197, 1)";

  return (
    <div className="ReplayTeam">
      <section
        className="Heading"
        style={{
          borderBottom: isRedTeam
            ? `3px solid ${teamColor}`
            : `3px solid ${teamColor}`,
        }}
      >
        <div className="TeamName">{team["teamId"]} Team</div>
        <div className="Score" style={{ color: teamColor, fontWeight: 600 }}>
          {isRedTeam ? round["redScore"] : round["blueScore"]}
        </div>
      </section>

      <section className="Players">
        {team["players"].map((playerId) => (
          <ReplayPlayer
            key={playerId}
            round={round}
            player={match["players"].find(
              (player) => player.puuid === playerId
            )}
          />
        ))}
      </section>
    </div>
  );
};

export default ReplayTeam;
