import React from "react";
import "./VideoControls.css";

import {
  BsFillSkipStartFill,
  BsFillSkipEndFill,
  BsPlayFill,
  BsPauseFill,
} from "react-icons/bs";

const VideoControls = ({
  selectedRound,
  setSelectedRound,
  handlePlayPause,
  isPlaying,
  totalRounds,
}) => {
  const increaseSelectedRound = () => {
    if (selectedRound < totalRounds - 1) {
      setSelectedRound(selectedRound + 1);
    }
  };

  const decreaseSelectedRound = () => {
    if (selectedRound > 0) {
      setSelectedRound(selectedRound - 1);
    }
  };

  return (
    <div className="VideoControls">
      <button type="button" onClick={decreaseSelectedRound}>
        <BsFillSkipStartFill size={40} />
      </button>

      <button type="button" onClick={increaseSelectedRound}>
        <BsFillSkipEndFill size={40} />
      </button>

      <button type="button" onClick={handlePlayPause}>
        {isPlaying ? <BsPauseFill size={40} /> : <BsPlayFill size={40} />}
      </button>
    </div>
  );
};

export default VideoControls;
