import React from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../features/auth/UserContext";
import "./Sidebar.css";

import { AiFillHome } from "react-icons/ai";
import { TbListSearch } from "react-icons/tb";
import { MdOutlineAutoGraph } from "react-icons/md";
import { FaMap, FaMagento } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { FiLogOut } from "react-icons/fi";

import Logo from "../UI/Logo";

import SidebarItem from "./SidebarItem";
import ranks from "../../assets/templates/compTiers.json";
import borders from "../../assets/templates/borderLevels.json";

const Sidebar = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const iconSize = 25;

  function findBorderForLevel(level) {
    let correctBorder = borders[0]; // Default to the first border

    for (let i = 1; i < borders.length; i++) {
      if (level < borders[i].startingLevel) {
        break;
      }
      correctBorder = borders[i];
    }

    return correctBorder;
  }

  const options = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: <AiFillHome size={iconSize} />,
    },
    {
      label: "Matches",
      path: "/matches",
      icon: <TbListSearch size={iconSize} />,
    },
    {
      label: "Performance",
      path: "/performance",
      icon: <MdOutlineAutoGraph size={iconSize} />,
    },
    { label: "Maps", path: "/maps", icon: <FaMap size={iconSize} /> },
    { label: "Agents", path: "/agents", icon: <FaMagento size={iconSize} /> },
  ];

  const settingsItem = {
    label: "Settings",
    path: "/settings",
    icon: <IoMdSettings size={iconSize} />,
  };

  const logoutItem = {
    label: "Logout",
    path: "/login",
    icon: <FiLogOut size={iconSize} />,
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className="Sidebar">
      <Logo />

      <hr />

      <div className="Profile">
        <div className="Account">
          <h2>
            {user && user.gameName ? user.gameName : "null"}{" "}
            <span>#{user && user.tagLine ? user.tagLine : "null"}</span>{" "}
          </h2>
        </div>

        <div className="Rank">
          <img
            src={
              user && user.competitiveTier
                ? ranks[user.competitiveTier].smallIcon
                : ranks[0].smallIcon
            }
            alt="VALORANT Rank"
            height={38}
          />
          <h3>
            {/* {" "}
            •{" "} */}
            <span className="color">
              {user && user.mmr ? user.mmr : 0} <span className="size">RR</span>
            </span>
          </h3>
        </div>

        <div className="AccLevel">
          <img
            src={
              user && user.accountLevel
                ? findBorderForLevel(user.accountLevel).levelNumberAppearance
                : borders[0].levelNumberAppearance
            }
            alt="VALORANT account level border"
            height={30}
          />

          <h4>{user && user.accountLevel ? user.accountLevel : 0}</h4>
        </div>
      </div>

      <div className="PageLinks">
        <ul>
          {options.map((option) => (
            <li key={option.label}>
              <SidebarItem option={option} />
            </li>
          ))}
        </ul>

        <div className="SettingsOption">
          <SidebarItem option={settingsItem} />
        </div>
        <div className="Logout" onClick={handleLogout}>
          <SidebarItem option={logoutItem} />
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
