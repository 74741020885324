import React from "react";
import "./RecentMatches.css";
import { Link } from "react-router-dom";

import MatchPreview from "./MatchPreview";

const RecentMatches = ({ matches }) => {
  const legendKeys = [
    "Agent",
    "Map",
    "Date",
    "Score",
    "ACS",
    "K/D/A",
    "KAST%",
    "FK:FD",
  ];

  return (
    <main className="MatchesContent">
      <div className="MatchesLegend">
        {legendKeys.map((legendKey) => (
          <div className="LegendKey" key={legendKey}>
            <p>{legendKey}</p>
          </div>
        ))}
      </div>

      {matches.map((match) => (
        <Link
          to={`/match/${match.matchId}`}
          className="MatchItemLink"
          key={match.matchId}
        >
          <MatchPreview match={match} />
        </Link>
      ))}
    </main>
  );
};

export default RecentMatches;
