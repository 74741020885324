import React from "react";
import { Radar } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const FormRadar = ({ stats }) => {
  const data = {
    labels: ["HS %", "KAST", "First Kill %", "Clutch %", "Multikill %"],
    datasets: [
      {
        label: "Stat",
        data: [
          stats.hsPercentage * 100,
          stats.kast * 100,
          stats.openingDuels * 100,
          stats.clutches * 100,
          stats.multikills * 100,
        ],
        fill: true,
        backgroundColor: "rgba(211, 0, 17, 0.2)",
        borderColor: "rgb(211, 0, 17)",
        pointBackgroundColor: "rgb(211, 0, 17)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(211, 0, 17)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Current Form",
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      r: {
        ticks: {
          display: false, // This will hide the tick labels on the axes if desired
          min: 0,
          max: 100,
        },
        angleLines: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="FormRadar">
      <Radar data={data} options={options} />
    </div>
  );
};

export default FormRadar;
