import React from "react";
import "./MapOverview.css";

import maps from "../../assets/templates/maps.json";

const MapOverview = ({ map }) => {
  const colors = {
    good: "#30c464",
    avg: "#ffc454",
    bad: "#ff4f4f",
  };

  const timesPlayed = map.timesPlayed !== 0 ? map.timesPlayed : 1;
  const winrate = map.wins / timesPlayed;
  const roundsPlayed = map.roundsPlayed !== 0 ? map.roundsPlayed : 1;
  const roundWinrate = map.roundsWon / roundsPlayed;

  const fiveFour = (
    (map.advantages["5v4Rounds"].totalWins /
      map.advantages["5v4Rounds"].totalRounds) *
    100
  ).toFixed(1);

  const fourFive = (
    (map.advantages["4v5Rounds"].totalWins /
      map.advantages["4v5Rounds"].totalRounds) *
    100
  ).toFixed(1);

  return (
    <div className="MapOverview">
      <div className="MapsItemContent">
        <section className="Heading">
          <img
            src={maps[map.mapId].listView}
            alt="List View Overhead of VALORANT Map"
          />
          <h1>{maps[map.mapId].displayName}</h1>
          {/* <LinkInto linkTo={`/map/${map.displayName}`} /> */}
        </section>

        <main className="MainContentStats">
          <section className="Top ContentSection">
            <div className="Winrate">
              Winrate: <br />
              <span
                style={{
                  color:
                    winrate >= 0.5
                      ? colors.good
                      : winrate >= 0.42
                      ? colors.avg
                      : colors.bad,
                }}
              >
                {(winrate * 100).toFixed(1)}%
              </span>
            </div>
            <div className="RoundsWon">
              Rounds Won: <br />
              <span>
                {map.roundsWon}/{map.roundsPlayed}{" "}
                <span
                  style={{
                    color:
                      roundWinrate >= 0.5
                        ? colors.good
                        : roundWinrate >= 0.42
                        ? colors.avg
                        : colors.bad,
                  }}
                >
                  ({(roundWinrate * 100).toFixed(0)}%)
                </span>
              </span>
            </div>
          </section>

          <section className="Bottom ContentSection">
            <div className="TimesPlayed">
              Played: <span>{map.timesPlayed} times</span>
            </div>
            <div className="KD">
              K/D:{" "}
              <span
                style={{
                  color:
                    map.kda.avgKDA >= 1.0
                      ? colors.good
                      : map.kda.avgKDA >= 0.9
                      ? colors.avg
                      : colors.bad,
                }}
              >
                {map.kda.avgKDA.toFixed(2)}
              </span>
            </div>
          </section>

          <section className="Bottom ContentSection">
            <div className="TimesPlayed">
              5v4 %:{" "}
              <span
                style={{
                  color:
                    fiveFour >= 70
                      ? colors.good
                      : fiveFour >= 65
                      ? colors.avg
                      : colors.bad,
                }}
              >
                {fiveFour}
              </span>
            </div>
            <div className="KD">
              4v5 %:{" "}
              <span
                style={{
                  color:
                    fourFive >= 30
                      ? colors.good
                      : fourFive >= 25
                      ? colors.avg
                      : colors.bad,
                }}
              >
                {fourFive}
              </span>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default MapOverview;
