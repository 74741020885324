import React from "react";

const Round = ({ round, selectedRound, setSelectedRound, setVideoTime }) => {
  const selectedStyling = {
    color: "white",
    backgroundColor: round["winningTeam"] === "Red" ? "#ff4655" : "#22ffc5",
    // border:
    //   round["winningTeam"] == "Red" ? "1px solid #858585" : "1px solid #858585",
  };

  const notSelectedStyling = {
    color: round["winningTeam"] === "Red" ? "#ff4655" : "#22ffc5",
    // border:
    //   round["winningTeam"] == "Red" ? "1px solid #858585" : "1px solid #858585",
  };

  const handleUpdateSelectedRound = () => {
    setVideoTime(round["roundNumber"]);
    setSelectedRound(round["roundNumber"]);
  };

  return (
    <div
      className="Round"
      style={
        round["roundNumber"] === selectedRound
          ? selectedStyling
          : notSelectedStyling
      }
      onClick={handleUpdateSelectedRound}
    >
      {round.roundNumber + 1}
    </div>
  );
};

export default Round;
