import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./ScoreboardTab.css";

import { fetchWithToken } from "../../../features/api/api";

import ScoreboardTeam from "./ScoreboardTeam";
import Loading from "../../../components/Loading";

import maps from "../../../assets/templates/maps.json";

const ScoreboardTab = () => {
  const [match, setMatch] = useState(null);
  const { matchId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await fetchWithToken(`/matches/${matchId}`);

        setMatch(data.data);
      } catch (error) {
        navigate("/matches", { replace: true });
        // Handle or display error
      }
    };

    fetchStats();
  }, []);

  const formatDate = (milliseconds) => {
    const date = new Date(milliseconds);
    const month = date.getMonth() + 1; // Add 1 to get the correct month (0-indexed)
    const day = date.getDate();
    const year = date.getFullYear();

    // Pad the month and day with leading zeros if necessary
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12;

    // Pad hours and minutes with leading zeros if necessary
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedMonth}/${formattedDay}/${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formatDuration = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}m ${seconds}s`;
  };

  return (
    <>
      {match ? (
        <main className="ScoreboardTab">
          <section className="ScoreHeading">
            <div className="Score">
              <span style={{ color: "#ff4655" }}>
                {match.matchInfo.redRoundsWon}
              </span>
              :
              <span style={{ color: "#22ffc5" }}>
                {match.matchInfo.blueRoundsWon}
              </span>
            </div>

            <div className="Map">{maps[match.matchInfo.mapId].displayName}</div>

            <div className="Date">
              <div className="StartDate">
                {formatDate(match.matchInfo.gameStartMillis)}
              </div>
              <div>•</div>
              <div className="Duration">
                {formatDuration(match.matchInfo.gameLengthMillis)}
              </div>
            </div>
          </section>

          <section className="Teams">
            <div className="RedTeam">
              {match.teams.map((team) => (
                <ScoreboardTeam team={team} match={match} key={team.teamId} />
              ))}
            </div>
          </section>
        </main>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ScoreboardTab;
