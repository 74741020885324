import React, { useRef, useState, useEffect } from "react";
import YouTube from "react-youtube";
import "./YoutubeVideo.css";

import Timeline from "../../Controls/Timeline/Timeline";
import Rounds from "../../Controls/Rounds/Rounds";
import PageTitle from "../../../../../components/Formats/PageTitle";

const YoutubeVideo = ({
  selectedVOD,
  durations,
  url,
  selectedRound,
  setSelectedRound,
  totalRounds,
  rounds,
}) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  const width = 1000;
  const height = width * 1.1;

  const headerHeight = (height - width * (9 / 16)) / 2;

  const opts = {
    height: height,
    width: width,
  };

  useEffect(() => {
    // Start the interval to update currentTime
    const intervalId = setInterval(() => {
      if (videoRef.current) {
        videoRef.current.internalPlayer.getCurrentTime().then((time) => {
          setCurrentTime(time);
        });
      }
    }, 100); // Update every second

    return () => {
      // Clear the interval when the component is unmounted
      clearInterval(intervalId);
    };
  }, [selectedRound]);

  useEffect(() => {
    if (
      currentTime >
      rounds[selectedRound]["durations"]["startTimestamp"] +
        rounds[selectedRound]["durations"]["roundTime"] +
        rounds[selectedRound]["durations"]["postRound"]
    ) {
      if (selectedRound + 1 === totalRounds) {
        setSelectedRound(0);
        setVideoTime(0);
      } else {
        setSelectedRound(selectedRound + 1);
      }
    }
  }, [currentTime]);

  useEffect(() => {
    if (videoRef.current) {
      setVideoStartTime();
    }
  }, [selectedRound]);

  const setVideoStartTime = () => {
    if (durations) {
      const startTimestamp = durations["startTimestamp"];

      setCurrentTime(startTimestamp);
      videoRef.current.internalPlayer.seekTo(startTimestamp);
    }
  };

  const getIdFromUrl = (url) => {
    const match = url.match(
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]{11})/
    );
    if (match) {
      return match[1];
    } else {
      return null;
    }
  };

  const handlePlayPause = () => {
    if (videoRef.current) {
      isPlaying
        ? videoRef.current.internalPlayer.pauseVideo()
        : videoRef.current.internalPlayer.playVideo();
    }
    setIsPlaying(!isPlaying);
  };

  const videoLoaded = () => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.internalPlayer.pauseVideo();

        setCurrentTime(selectedVOD.startTime);
        videoRef.current.internalPlayer.seekTo(selectedVOD.startTime);
      }
    }, "1000");
  };

  const handleScrubberChange = (e) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    videoRef.current.internalPlayer.seekTo(time);
  };

  const setVideoTime = (roundNum) => {
    const roundStartTime = rounds[roundNum]["durations"]["startTimestamp"];
    setCurrentTime(roundStartTime);
    videoRef.current.internalPlayer.seekTo(roundStartTime);
  };

  return (
    <main className="VideoContainer">
      <section className="VideoYoutube">
        <div
          className="Header Blocker"
          style={{ width: width, height: headerHeight }}
        ></div>

        <PageTitle title="Match" subtitle="Match specific statistics" />

        <YouTube
          videoId={getIdFromUrl(url)}
          opts={opts}
          ref={videoRef}
          onReady={videoLoaded}
        />

        <div
          className="Footer Blocker"
          style={{ width: width, height: headerHeight }}
        ></div>
      </section>

      <section className="ControlsContainer">
        <Timeline
          selectedRound={selectedRound}
          setSelectedRound={setSelectedRound}
          handlePlayPause={handlePlayPause}
          isPlaying={isPlaying}
          totalRounds={totalRounds}
          durations={durations}
          currentTime={currentTime}
          handleScrubberChange={handleScrubberChange}
          round={rounds[selectedRound]}
        />

        <Rounds
          rounds={rounds}
          selectedRound={selectedRound}
          setSelectedRound={setSelectedRound}
          setVideoTime={setVideoTime}
        />
      </section>
    </main>
  );
};

export default YoutubeVideo;
