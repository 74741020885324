import React from "react";
import { Link } from "react-router-dom";
import "./CallButton.css";

const CallButton = ({ text, linkTo }) => {
  return (
    <div className="CallButton">
      <Link to={linkTo} className="LinkIntoLink" key={linkTo}>
        <button>{text}</button>
      </Link>
    </div>
  );
};

export default CallButton;
