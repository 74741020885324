import React from "react";
import "./Missing.css";

import Logo from "../components/UI/Logo";
import missingImage from "../assets/gifs/lost-404.gif";

const Missing = () => {
  return (
    <div className="Missing">
      <div className="MissingContainer">
        <Logo />
        <img src={missingImage} alt="Yoru confused" height={80} />
        <h3>Page not found...</h3>
      </div>
    </div>
  );
};

export default Missing;
