import React, { useState } from "react";
import { useParams, Link, Outlet } from "react-router-dom";
import "./Match.css";

import Sidebar from "../../components/Sidebar/Sidebar";
import PageTitle from "../../components/Formats/PageTitle";

const Match = () => {
  const { matchId } = useParams();

  const tabs = [
    {
      linkName: "scoreboard",
      component: (
        <Link to={`/match/${matchId}/scoreboard`}>
          <h4>Scoreboard</h4>
        </Link>
      ),
    },
    {
      linkName: "replay",
      component: (
        <Link to={`/match/${matchId}/replay`}>
          <h4>Replay</h4>
        </Link>
      ),
    },
  ];

  return (
    <main className="Match Page">
      <Sidebar />

      <section className="PageContent" style={{ overflow: "hidden" }}>
        <PageTitle title="Match" subtitle="Match specific statistics" />

        <div className="MatchTabHeader">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`MatchTabLabel ${
                window.location.href.includes(tab.linkName) ? "TabActive" : ""
              }`}
            >
              {tab.component}
            </div>
          ))}
        </div>

        <Outlet />
      </section>
    </main>
  );
};

export default Match;
