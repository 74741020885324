import React from "react";
import "./Settings.css";

import { useUser } from "../../features/auth/UserContext";

import Sidebar from "../../components/Sidebar/Sidebar";
import PageTitle from "../../components/Formats/PageTitle";

const Maps = () => {
  const { user } = useUser();

  const date = new Date(user.createdAt);

  return (
    <main className="Settings Page">
      <Sidebar />
      {/* <p>{stats ? JSON.stringify(stats) : ""}</p> */}

      <section className="PageContent">
        <PageTitle title="Settings" subtitle="All your user information" />

        <main className="MainContent">
          <div
            className="ContentBox"
            style={{ flexBasis: "80%", minWidth: "340px" }}
          >
            <div className="AccountName">
              <h2>
                {user && user.gameName ? user.gameName : "null"}{" "}
                <span>#{user && user.tagLine ? user.tagLine : "null"}</span>{" "}
              </h2>
            </div>

            <div className="DateJoined">
              <h3>Date Joined: {date.toISOString().split("T")[0]}</h3>
            </div>
          </div>
        </main>
      </section>
    </main>
  );
};

export default Maps;
