import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { fetchWithToken } from "../../../features/api/api";
import Loading from "../../../components/Loading";
import Replay from "./Replay";
import SelectVOD from "./LinkComponents/SelectVOD";

const ReplayTab = () => {
  const [data, setData] = useState(null);
  const [selectedVOD, setSelectedVOD] = useState(null);
  const { matchId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWithToken(`/matches/replay/${matchId}`);

        setData(data);
      } catch (error) {
        navigate(`/match/${matchId}/scoreboard`, { replace: true });
        // Handle or display error
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {data ? (
        <section className="ReplayTab" style={{ marginTop: 60, height: "85%" }}>
          {selectedVOD ? (
            <Replay selectedVOD={selectedVOD} data={data} />
          ) : (
            <SelectVOD
              vodLinks={data.videoLinks}
              setSelectedVOD={setSelectedVOD}
            />
          )}
        </section>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ReplayTab;
