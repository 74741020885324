import React from "react";
import "./VODItem.css";
import { FaYoutube, FaEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";

const VODItem = ({ link, setIsValid, setNewVODLink, setSelectedVOD }) => {
  const { matchId } = useParams();

  const handleEditClick = (event) => {
    event.stopPropagation();

    setNewVODLink(link.url);
    setIsValid(true);
  };

  const handleVODClick = () => {
    const vodData = {
      matchId,
      startTime: link.startTime,
      url: link.url,
    };

    setSelectedVOD(vodData);
  };

  return (
    <div className="VODItem" onClick={handleVODClick}>
      <div className="VODItemIcon">
        <FaYoutube size={25} />
      </div>

      <h3>{link.user}'s VOD</h3>

      <div className="VODItemEdit" onClick={handleEditClick}>
        <FaEdit size={25} />
      </div>
    </div>
  );
};

export default VODItem;
