import React, { createContext, useState, useContext, useEffect } from "react";
import { fetchWithToken } from "../api/api";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  // has token ?!? this can be used to immeditelity direct to dashboard so you dont see the site

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("post-plant-token");

      if (!token) {
        setUser(null);
        return;
      }
      setIsAuthenticating(true);
      // TODO: CHECK setuser in callback
      try {
        const response = await fetchWithToken("/oauth/me");
        setUser(response.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser(null);
      } finally {
        setIsAuthenticating(false);
      }
    };

    fetchUserData();
  }, []);

  const logout = () => {
    localStorage.removeItem("post-plant-token");
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, isAuthenticating, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
