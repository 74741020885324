import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "./Login.css";
import { fetchWithoutToken } from "../../features/api/api";

import { Link } from "react-router-dom";
import { useUser } from "../../features/auth/UserContext";
import LoginLoading from "../../components/LoginLoading";

import PostPlantLogo from "../../assets/logos/post-plant-logo.png";
import RiotGamesLogo from "../../assets/logos/riot-fist-logo.png";

const Login = () => {
  const { user, isAuthenticating } = useUser();
  const [isChecked, setIsChecked] = useState(false);

  if (isAuthenticating) {
    return <LoginLoading />;
  }

  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  const fetchOauthLink = async () => {
    try {
      const data = await fetchWithoutToken("/oauth");
      return data.link;
    } catch (error) {
      console.log("An error occurred.");
    }
  };

  const handleRiotSignIn = async (e) => {
    e.preventDefault();

    const oauthLink = await fetchOauthLink();
    window.location.href = oauthLink;
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <main className="signup-container">
      <div className="signup-form">
        <Link to="/" className="signup-link-wrapper">
          <div className="signup-title">
            <img
              src={PostPlantLogo}
              alt="Post Plant logo"
              width={44}
              height={41}
            />
            <h1>Post-Plant</h1>
          </div>
        </Link>

        <div className="social-login">
          <button
            className="riot-btn"
            onClick={handleRiotSignIn}
            disabled={!isChecked}
          >
            <img
              src={RiotGamesLogo}
              alt="Riot Games Logo"
              width={50}
              height={50}
            />
            Sign in with Riot
          </button>
        </div>

        <div className="form-container">
          <hr />
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="terms-checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="terms-checkbox">
              I acknowledge signing up shares my data with Post-Plant.
            </label>
          </div>

          <p className="bottomSignup">
            By signing up you agree to our{" "}
            <Link to="/terms-of-service">Terms of Service</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </main>
  );
};

export default Login;
