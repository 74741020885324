import React from "react";
import "./Rounds.css";

import Round from "./Round";

const Rounds = ({ rounds, selectedRound, setSelectedRound, setVideoTime }) => {
  return (
    rounds && (
      <div className="Rounds">
        {rounds.map((round) => (
          <Round
            round={round}
            selectedRound={selectedRound}
            setSelectedRound={setSelectedRound}
            setVideoTime={setVideoTime}
            key={round.roundNumber}
          />
        ))}
      </div>
    )
  );
};

export default Rounds;
