import React from "react";
import "./ReplayPlayer.css";

import credsIconWhite from "../../../../../assets/icons/creds_icon_white.png";
import credsIconYellow from "../../../../../assets/icons/creds_icon_yellow.png";
import blankArmor from "../../../../../assets/icons/blank_armor.png";

import ShortenedName from "./ShortenedName";
import agents from "../../../../../assets/templates/agents.json";

const ReplayPlayer = ({ round, player }) => {
  const agent = agents[player["characterId"]];
  const playerId = player["puuid"];
  const playerEconomy = round["economy"][playerId];
  const weaponIcon = playerEconomy["weapon"]
    ? `https://media.valorant-api.com/weapons/${playerEconomy["weapon"]}/killstreamicon.png`
    : blankArmor;
  const armorIcon = playerEconomy["armor"]
    ? `https://media.valorant-api.com/gear/${playerEconomy["armor"]}/displayicon.png`
    : blankArmor;

  return (
    <section className="ReplayPlayer">
      <div className="Agent">
        <img
          src={agent["displayIcon"]}
          alt="VALORANT Agent Display Icon"
          height={50}
          className={
            player["teamId"] === "Red" ? "Red-outline" : "Blue-outline"
          }
        />
      </div>

      <div className="PlayerName">
        <ShortenedName name={player["gameName"]} maxLength={6} />
      </div>

      <div className="PlayerEconomy">
        <p className="Spent">
          -<img src={credsIconYellow} height="10" alt="Credits Icon White" />{" "}
          {playerEconomy["spent"].toLocaleString()}
        </p>
        <p>
          <img src={credsIconWhite} height="10" alt="Credits Icon Yellow" />{" "}
          {playerEconomy["remaining"].toLocaleString()}
        </p>
      </div>

      <div className="Arsenal">
        <div className="Armor">
          <img src={armorIcon} alt="Armor Icon" height={25} />
        </div>
        <div className="Weapon">
          <img src={weaponIcon} alt="Weapon Icon" height={20} />
        </div>
      </div>
    </section>
  );
};

export default ReplayPlayer;
