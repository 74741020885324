import React from "react";
import "./AgentPreview.css";

import agents from "../../assets/templates/agents.json";

const AgentPreview = ({ agent }) => {
  const agentHeight = 40;

  const timesPlayed = agent.timesPlayed !== 0 ? agent.timesPlayed : 1;
  return (
    <li key={agent._id}>
      <div className="AgentPreview">
        <div className="Agent AgentItemContainer">
          <img
            src={agents[agent.agentId].displayIcon}
            alt="VALORANT Agent Icon"
            width={agentHeight}
            height={agentHeight}
            className="AgentIcon"
          />

          <h1>{agent ? agents[agent.agentId].displayName : "null"}</h1>
        </div>

        <div className="MatchesPlayed AgentItemContainer">
          {agent.timesPlayed}
        </div>
        <div className="WinPercentage AgentItemContainer">
          {(agent.winRate.avgWinRate * 100).toFixed(1)}%
        </div>

        <div className="Kills AgentItemContainer">
          {(agent.kda.totalKills / timesPlayed).toFixed(1)}
        </div>
        <div className="Deaths AgentItemContainer">
          {(agent.kda.totalDeaths / timesPlayed).toFixed(1)}
        </div>
        <div className="Assists AgentItemContainer">
          {(agent.kda.totalAssists / timesPlayed).toFixed(1)}
        </div>

        <div className="ACS AgentItemContainer">
          {agent.acs.avgACS.toFixed(1)}
        </div>
        <div className="ADR AgentItemContainer">
          {agent.adr.avgADR.toFixed(1)}
        </div>
        <div className="HSPercentage AgentItemContainer">
          {(agent.hsPercentage.avgHSPercentage * 100).toFixed(1)}%
        </div>
        <div className="KAST AgentItemContainer">
          {(agent.KAST.avgKAST * 100).toFixed(1)}%
        </div>

        {/* <div className="Form ItemContainer">{match.FormScore}</div>
        <div className="Impact ItemContainer">{match.ImpactScore}</div>
        <div className="Positioning ItemContainer">
          {match.PositioningScore}
        </div>
        <div className="Utility ItemContainer">{match.UtilityScore}</div> */}
      </div>
    </li>
  );
};

export default AgentPreview;
