import React, { useState, useEffect } from "react";
import "./Matches.css";
import { Link } from "react-router-dom";

import { fetchWithToken } from "../../features/api/api";

import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar/Sidebar";
import PageTitle from "../../components/Formats/PageTitle";
import MatchItem from "./MatchItem";

const Matches = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await fetchWithToken("/matches");
        setStats(data);
      } catch (error) {
        // Handle or display error
      }
    };

    fetchStats();
  }, []);

  const legendKeys = [
    "Agent",
    "Map",
    "Date",
    "Score",
    "ACS",
    "K/D/A",
    "KAST%",
    "FK:FD",
  ];

  return (
    <main className="Matches Page">
      <Sidebar />
      {/* <p>{stats ? JSON.stringify(stats) : ""}</p> */}

      <section className="PageContent">
        <PageTitle
          title="Matches"
          subtitle="All your competitive ranked games"
        />
        {stats ? (
          <main className="AllMatches">
            <div className="MatchesLegend">
              {legendKeys.map((legendKey) => (
                <div className="LegendKey" key={legendKey}>
                  <p>{legendKey}</p>
                </div>
              ))}
            </div>

            {stats.matchList.map((match) => (
              <Link
                to={`/match/${match.matchId}`}
                className="MatchItemLink"
                key={match.matchId}
              >
                <MatchItem match={match} />
              </Link>
            ))}
          </main>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default Matches;
