import React from "react";
import "./Multikills.css";

const Multikills = ({ stats }) => {
  return (
    <div className="Multikills">
      <div className="MultikillContainer">
        <div className="PerRoundMulti">
          <h2 className="MultikillTitle">Per round</h2>
          <h2>{(stats["1k"] / stats.totalRounds).toFixed(2)}</h2>
          <h2>{(stats["2k"] / stats.totalRounds).toFixed(2)}</h2>
          <h2>{(stats["3k"] / stats.totalRounds).toFixed(2)}</h2>
          <h2>{(stats["4k"] / stats.totalRounds).toFixed(2)}</h2>
          <h2>{(stats["5k"] / stats.totalRounds).toFixed(2)}</h2>
          <h2>{(stats["6k"] / stats.totalRounds).toFixed(2)}</h2>
        </div>
        <div className="TypeMulti">
          <h2 className="MultikillTitle"></h2>
          <h2>1K</h2>
          <h2>2K</h2>
          <h2>3K</h2>
          <h2>4K</h2>
          <h2>5K</h2>
          <h2>6K</h2>
        </div>
        <div className="TotalMulti">
          <h2 className="MultikillTitle">Total</h2>
          <h2>{stats["1k"]}</h2>
          <h2>{stats["2k"]}</h2>
          <h2>{stats["3k"]}</h2>
          <h2>{stats["4k"]}</h2>
          <h2>{stats["5k"]}</h2>
          <h2>{stats["6k"]}</h2>
        </div>
      </div>
    </div>
  );
};

export default Multikills;
