import React, { useState, useMemo } from "react";
import "./Replay.css";

import YoutubeVideo from "./Content/Video/YoutubeVideo";
import ReplayTeams from "./Content/Teams/ReplayTeams";
import Loading from "../../../components/Loading";

const Replay = ({ selectedVOD, data }) => {
  const [selectedRound, setSelectedRound] = useState(0);
  const match = data.data;

  useMemo(() => {
    if (match) {
      let previousTime = selectedVOD.startTime - 15;
      match["rounds"].forEach((round) => {
        const durations = round["durations"];
        durations["startTimestamp"] = previousTime;
        if (durations["buyPhase"] === 45) {
          durations["startTimestamp"] += 15;
        }
        previousTime += durations["totalDuration"];
      });
    }
  }, [match]);

  return (
    <>
      {match ? (
        <main className="Replay">
          <section className="Content">
            <YoutubeVideo
              selectedVOD={selectedVOD}
              durations={
                match ? match["rounds"][selectedRound]["durations"] : null
              }
              url={selectedVOD.url}
              selectedRound={selectedRound}
              setSelectedRound={setSelectedRound}
              totalRounds={match ? match["rounds"].length : 1}
              rounds={match["rounds"]}
            />
            <ReplayTeams match={match} selectedRound={selectedRound} />
          </section>
        </main>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Replay;
