import React from "react";

import { Line } from "rc-progress";
import "./ProgressBar.css";

const ProgressBar = ({ parseStatus }) => {
  return (
    <>
      {parseStatus.isParsing ? (
        <div className="ProgressBar">
          <p>Parsing {parseStatus.needToParse} matches...</p>
          <h4>{Math.round((parseStatus.progress * 1000) / 10)}%</h4>
          <Line
            percent={parseStatus.progress * 100}
            strokeWidth={0.3}
            strokeColor="#d30011"
            trailColor="#27282c"
            style={{ position: "absolute" }}
          />
        </div>
      ) : (
        <div className="ProgressBar"></div>
      )}
    </>
  );
};

export default ProgressBar;
