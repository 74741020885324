import React from "react";
import "./Scrubber.css";

const Scrubber = ({ durations, currentTime, handleScrubberChange, round }) => {
  const roundLength = durations["roundTime"] + durations["postRound"];

  return (
    <section className="Scrubber">
      <input
        type="range"
        min={durations["startTimestamp"]}
        max={durations["startTimestamp"] + roundLength}
        step={0.01}
        value={currentTime}
        onChange={handleScrubberChange}
        className="slider"
      />

      <div className="Events">
        <div className="Kills">
          {round.kills.map((kill) => (
            <div
              className="Kill"
              style={{
                left:
                  (kill.timeSinceRoundStartMillis / 1000 / roundLength) * 880,
                backgroundColor:
                  kill.killerTeamColor === "Red"
                    ? "rgb(255, 70, 85)"
                    : "rgb(34, 255, 197)",
              }}
              key={kill.victim}
            ></div>
          ))}

          {round.plantTime !== 0 ? (
            <div
              className="Plant"
              style={{ left: (round.plantTime / 1000 / roundLength) * 880 }}
            >
              <div className="PlantBox">
                <h4>{round.plantSite}</h4>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Scrubber;
