import React, { useState } from "react";
import "./AddVOD.css";

import { FaPlus } from "react-icons/fa";

const AddVOD = ({ isValid, setIsValid, setNewVODLink }) => {
  const [inputValue, setInputValue] = useState("");

  const isValidYouTubeUrl = (url) => {
    try {
      new URL(url);

      const regExp = /^(https?:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;
      return regExp.test(url);
    } catch (e) {
      // The URL constructor will throw an error if the URL is not valid
      return false;
    }
  };

  const checkLink = () => {
    if (isValidYouTubeUrl(inputValue)) {
      setIsValid(true);
      setNewVODLink(inputValue);
    } else {
      setIsValid(false);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="AddVOD">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter YouTube URL"
      />

      <button onClick={checkLink}>
        <FaPlus /> <h3>Add VOD</h3>
      </button>

      {isValid !== null && (
        <p style={{ color: "red" }}>{!isValid && "Invalid Youtube Link"}</p>
      )}
    </div>
  );
};

export default AddVOD;
