import React from "react";
import "./SidebarItem.css";

import { NavLink } from "react-router-dom";

const SidebarItem = ({ option }) => {
  return (
    <div className="SidebarItem">
      <NavLink to={option.path} className="LinkContainer">
        <i className="SidebarIcon">{option.icon}</i>
        <h5>{option.label}</h5>
        <div className="SelectBox"></div>
      </NavLink>
    </div>
  );
};

export default SidebarItem;
