import React from "react";
import "./Timeline.css";

import VideoControls from "./VideoControls";
import Scrubber from "./Scrubber";

const Timeline = ({
  selectedRound,
  setSelectedRound,
  handlePlayPause,
  isPlaying,
  totalRounds,
  durations,
  currentTime,
  handleScrubberChange,
  round,
}) => {
  return (
    <section className="Timeline">
      <VideoControls
        selectedRound={selectedRound}
        setSelectedRound={setSelectedRound}
        handlePlayPause={handlePlayPause}
        isPlaying={isPlaying}
        totalRounds={totalRounds}
      />

      <Scrubber
        durations={durations}
        currentTime={currentTime}
        handleScrubberChange={handleScrubberChange}
        round={round}
      />
    </section>
  );
};

export default Timeline;
