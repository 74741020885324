import React from "react";
import "./OpeningDuels.css";

const OpeningDuels = ({ stats, totalRounds, totalMatches }) => {
  return (
    <div className="OpeningDuels">
      <div className="OpeningStat">
        <div className="OpeningWord">
          <h1>Attempts</h1>
          <p>How often you are involved in the opening duel</p>
        </div>
        <h2>{((stats.totalOpeningDuels / totalRounds) * 100).toFixed(2)} %</h2>
      </div>

      <div className="OpeningStat">
        <div className="OpeningWord">
          <h1>Successes</h1>
          <p>Rate at which you win the opening duel</p>
        </div>
        <h2>
          {((stats.totalFirstKills / stats.totalOpeningDuels) * 100).toFixed(2)}{" "}
          %
        </h2>
      </div>

      <div className="OpeningStat">
        <div className="OpeningWord">
          <h1>Per Match</h1>
          <p>First kills : First deaths per match</p>
        </div>
        <h2>
          {(stats.totalFirstKills / totalMatches).toFixed(1)} :{" "}
          {(stats.totalFirstDeaths / totalMatches).toFixed(1)}
        </h2>
      </div>
    </div>
  );
};

export default OpeningDuels;
