import React from "react";
import "./PageTitle.css";

const PageTitle = ({ title, subtitle }) => {
  return (
    <section className="PageTitle">
      <h1>{title}</h1>
      <p>{subtitle}</p>
    </section>
  );
};

export default PageTitle;
