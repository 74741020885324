import React, { useState, useEffect } from "react";
import "./Performance.css";

import { fetchWithToken } from "../../features/api/api";

import Sidebar from "../../components/Sidebar/Sidebar";
import PageTitle from "../../components/Formats/PageTitle";
import Loading from "../../components/Loading";
import WinratePerf from "./Content/WinratePerf";
import Clutches from "./Content/Clutches";
import Form from "./Content/Form";
import Multikills from "./Content/Multikills";
import Trades from "./Content/Trades";
import OpeningDuels from "./Content/OpeningDuels";

const Performance = () => {
  const [stats, setStats] = useState(null);
  const [matchHistory, setMatchHistory] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await fetchWithToken("/stats/general");
        setStats(data);

        const dataMatches = await fetchWithToken("/matches");
        setMatchHistory(dataMatches.matchList);
      } catch (error) {
        // Handle or display error
      }
    };

    fetchStats();
  }, []);

  return (
    <main className="Performance Page">
      <Sidebar />
      {/* <p>{stats ? JSON.stringify(stats) : ""}</p> */}

      <section className="PageContent">
        <PageTitle
          title="Performance"
          subtitle="Lifetime ratings, statistics, and ranking"
        />

        {stats && matchHistory ? (
          <main className="MainContent">
            {stats && stats.winRate.totalMatches !== 0 ? (
              <>
                <section className="TopContent" style={{ marginTop: "-45px" }}>
                  <div
                    className="ContentBox"
                    style={{ flexBasis: "25%", minWidth: "340px" }}
                  >
                    <h2>Winrate</h2>
                    <WinratePerf stats={stats.winRate} />
                  </div>

                  <div className="ContentBox" style={{ flexBasis: "75%" }}>
                    <h2>Clutches</h2>

                    <Clutches stats={stats.clutches} />
                  </div>
                </section>

                <section className="MiddleContent">
                  <div
                    className="ContentBox"
                    style={{
                      flexBasis: "100%",
                      padding: 0,
                      overflow: "hidden",
                      minWidth: "920px",
                    }}
                  >
                    <Form stats={stats} matchList={matchHistory.slice(0, 30)} />
                  </div>
                </section>

                <section className="BottomContent">
                  <div className="ContentBox" style={{ flexBasis: "33%" }}>
                    <h2>Multikills</h2>

                    <Multikills stats={stats.multikills} />
                  </div>

                  <div className="ContentBox" style={{ flexBasis: "33%" }}>
                    <h2>Opening Duels</h2>

                    <OpeningDuels
                      stats={stats.openingDuels}
                      totalRounds={stats.winRate.roundsPlayed}
                      totalMatches={stats.winRate.totalMatches}
                    />
                  </div>

                  <div className="ContentBox" style={{ flexBasis: "33%" }}>
                    <h2>Trades</h2>

                    <Trades
                      stats={stats.trades}
                      totalMatches={stats.winRate.totalMatches}
                      totalDeaths={stats.kda.totalDeaths}
                      totalKills={stats.kda.totalKills}
                    />
                  </div>
                </section>
              </>
            ) : null}
          </main>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default Performance;
