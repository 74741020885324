import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.css";

import { fetchWithToken } from "../../features/api/api";

import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar/Sidebar";
import PageTitle from "../../components/Formats/PageTitle";
import ProgressBar from "../../components/UI/ProgressBar";
import BestAgent from "./BestAgent";
import BestMap from "./BestMap";
import RankHistory from "./RankHistory";
import FormRadar from "./FormRadar";
import RecentMatches from "./RecentMatches";
import Winrate from "./Winrate";

const Dashboard = () => {
  const [stats, setStats] = useState(null);
  const [parseStatus, setParseStatus] = useState({
    alreadyParsed: 0,
    isParsing: false,
    needToParse: 0,
    progress: 0,
  });

  useEffect(() => {
    // first i need to get existing data...
    const fetchStats = async () => {
      try {
        const data = await fetchWithToken("/stats/dashboard");
        setStats(data);
      } catch (error) {
        console.log("1: ", error);
      }
    };
    fetchStats();

    // Initiate parsing
    const startParsing = async () => {
      try {
        await fetchWithToken("/stats/start-parsing", "POST");
      } catch (error) {
        console.log("2: ", error);
      }
    };
    startParsing();

    const fetchStatus = async () => {
      try {
        const data = await fetchWithToken("/stats/parse-status");
        setParseStatus(data.parseStatus);
        return data;
      } catch (error) {
        console.log("3: ", error);
      }
    };
    fetchStatus();

    // Polling for parsing status
    const intervalId = setInterval(async () => {
      const data = await fetchStatus();

      if (!data) {
        clearInterval(intervalId);
        return;
      }

      if (!data.parseStatus.isParsing) {
        clearInterval(intervalId);
        // Handle completion of parsing
        fetchStats();
      }
    }, 2000); // Poll every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <main className="Dashboard Page">
      <Sidebar />

      <section className="PageContent">
        <PageTitle
          title="Dashboard"
          subtitle="All your stat summaries on one page"
        />

        {stats ? (
          <main className="MainContent">
            <ProgressBar parseStatus={parseStatus} />
            {stats && stats.bestAgent.winPercentage !== 0 ? (
              <>
                <section className="TopContent" style={{ marginTop: "-45px" }}>
                  <Link
                    to="/agents"
                    style={{ flexBasis: "25%", minWidth: 300 }}
                    className="Link"
                  >
                    <div className="ContentBox">
                      <h2>Best Agent</h2>
                      {/* Best Agent: {round(stats.winRate.avgWinRate * 100)}% */}
                      <BestAgent stats={stats.bestAgent} />
                    </div>
                  </Link>

                  <Link
                    to="/maps"
                    style={{ flexBasis: "25%", minWidth: 300 }}
                    className="Link"
                  >
                    <div className="ContentBox">
                      <h2>Best Map</h2>

                      <BestMap stats={stats.bestMap} />
                    </div>
                  </Link>

                  <div className="ContentBox">
                    <h2>Recent Matches</h2>

                    <RecentMatches matches={stats.recentMatches} />
                  </div>
                </section>

                <section className="BottomContent">
                  <Link
                    to="/performance"
                    style={{ flexBasis: "25%" }}
                    className="Link"
                  >
                    <div className="ContentBox">
                      <h2>Form</h2>

                      <FormRadar stats={stats.form} />
                    </div>
                  </Link>

                  <Link
                    to="/performance"
                    style={{ flexBasis: "50%" }}
                    className="Link"
                  >
                    <div className="ContentBox">
                      <h2>Rank History</h2>

                      <RankHistory stats={stats.rankHistory} />
                    </div>
                  </Link>

                  <Link
                    to="/performance"
                    style={{ flexBasis: "25%" }}
                    className="Link"
                  >
                    <div className="ContentBox">
                      <h2>Winrate</h2>

                      <Winrate stats={stats.winRate} />
                    </div>
                  </Link>
                </section>
              </>
            ) : (
              <Loading />
            )}
          </main>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default Dashboard;
