import React from "react";
import "./Winrate.css";

const Winrate = ({ stats }) => {
  let winratePercentage = (stats.avgWinRate * 100).toFixed(1);

  const colors = {
    good: "rgba(48, 196, 100, 0.1)", // #30c464
    avg: "rgba(255, 196, 84, 0.1)", // #ffc454
    bad: "rgba(255, 79, 79, 0.1)", // #ff4f4f
  };

  const colorsSolid = {
    good: "#30c464",
    avg: "#ffc454",
    bad: "#ff4f4f",
  };

  const solidColor =
    winratePercentage >= 50
      ? colorsSolid.good
      : winratePercentage >= 40
      ? colorsSolid.avg
      : colorsSolid.bad;

  return (
    <div className="Winrate">
      <div
        className="WinrateCircle"
        style={{
          backgroundColor:
            winratePercentage >= 50
              ? colors.good
              : winratePercentage >= 40
              ? colors.avg
              : colors.bad,
          border: `5px solid ${solidColor}`,
        }}
      >
        <div className="WinrateText">
          <h2
            style={{
              color: solidColor,
            }}
          >
            {winratePercentage}%
          </h2>
          <p
            style={{
              color: solidColor,
              fontSize: "1.5rem",
            }}
          >
            {stats.matchesWon} W - {stats.totalMatches - stats.matchesWon} L
          </p>
        </div>
      </div>
    </div>
  );
};

export default Winrate;
