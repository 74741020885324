import "./App.css";
import "./pages/Page.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Frontpage from "./pages/Frontpage/Frontpage";
import Login from "./pages/Login/Login";

import Dashboard from "./pages/Dashboard/Dashboard";
import Matches from "./pages/Matches/Matches";
import Match from "./pages/Match/Match";
import ScoreboardTab from "./pages/Match/ScoreboardTab/ScoreboardTab";
import ReplayTab from "./pages/Match/ReplayTab/ReplayTab";
import Performance from "./pages/Performance/Performance";
import Maps from "./pages/Maps/Maps";
import Agents from "./pages/Agents/Agents";
import Settings from "./pages/Settings/Settings";

import Missing from "./pages/Missing";
import Callback from "./pages/Callback";
import TOS from "./pages/TOS/TOS";
import PrivacyPolicy from "./pages/Privacy-Policy/PrivacyPolicy";

import { UserProvider } from "./features/auth/UserContext";
import ProtectedRoute from "./features/auth/ProtectedRoute";

// Protected Route
const protect = (component) => {
  return <ProtectedRoute>{component}</ProtectedRoute>;
};

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route exact path="/" element={<Frontpage />} />

            <Route path="/login" element={<Login />} />

            <Route path="/dashboard" element={protect(<Dashboard />)} />
            <Route path="/matches" element={protect(<Matches />)} />
            <Route path="/match/:matchId" element={<Match />}>
              <Route index element={<Navigate replace to="scoreboard" />} />
              <Route path="scoreboard" element={<ScoreboardTab />} />
              <Route path="replay" element={<ReplayTab />} />
            </Route>
            <Route path="/performance" element={protect(<Performance />)} />
            <Route path="/maps" element={protect(<Maps />)} />
            <Route path="/agents" element={protect(<Agents />)} />
            <Route path="/settings" element={protect(<Settings />)} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TOS />} />
            <Route path="/oauth/callback" element={<Callback />} />

            <Route path="*" element={<Missing />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </UserProvider>
  );
}

export default App;
