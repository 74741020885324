import React from "react";
import "./BestMap.css";

import maps from "../../assets/templates/maps.json";

const BestMap = ({ stats }) => {
  const round = (number) => {
    return Math.round(number * 100.0) / 100.0;
  };

  const colors = {
    good: "#30c464",
    avg: "#ffc454",
    bad: "#ff4f4f",
  };

  const mapStats = [
    {
      statName: "Win %",
      statScore: `${round(stats.winPercentage * 100)}%`,
      color: stats.winPercentage > 0.5 ? colors.good : colors.avg,
    },
    {
      statName: "K/D",
      statScore: round(stats.kd),
      color: stats.kd > 0.99 ? colors.good : colors.avg,
    },
    {
      statName: "Clutch %",
      statScore: `${round(stats.clutchPercentage * 100)}%`,
      color: stats.clutchPercentage > 0.2 ? colors.good : colors.avg,
    },
  ];

  return (
    <div className="BestMap">
      <section className="MapImage">
        <img
          src={maps[stats.mapId].splash}
          alt="VALORANT map from aerial view"
          width={108}
          height={198}
          style={{
            width: "100%",
            height: 150,
            objectFit: "cover",
            objectPosition: "0% 40%",
          }}
        />
        <h1>{stats ? maps[stats.mapId].displayName : "null"}</h1>
      </section>

      <section className="MapStats">
        {mapStats.map((stats) => (
          <li key={stats.statName}>
            <div className="Stat">
              <div>{stats.statName}:</div>
              <div>
                <span className="StatsScore" style={{ color: stats.color }}>
                  {stats.statScore}
                </span>
              </div>
            </div>
          </li>
        ))}
      </section>
    </div>
  );
};

export default BestMap;
