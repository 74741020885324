import React from "react";
import "./WinratePerf.css";

const WinratePerf = ({ stats }) => {
  const round = (number) => {
    return Math.round(number * 100.0) / 100.0;
  };

  const colors = {
    good: "#30c464",
    avg: "#ffc454",
    bad: "#ff4f4f",
  };

  return (
    <div className="WinratePerf">
      <h1
        style={{
          color:
            stats.avgWinRate >= 0.5
              ? colors.good
              : stats.avgWinRate >= 0.42
              ? colors.avg
              : colors.bad,
        }}
      >
        {round(stats.avgWinRate * 100)} %
      </h1>

      <section className="WinrateContainer">
        {stats.matchesWon} Wins
        <div className="WinrateBar">
          <div
            className="WinratePercentage"
            style={{ width: stats.avgWinRate * 150 }}
          ></div>
        </div>
        {stats.totalMatches - stats.matchesWon} Losses
      </section>
      <h2>{stats.totalMatches} total games played</h2>

      <hr />

      <h1
        style={{
          color:
            stats.avgWinRate > 0.5
              ? colors.good
              : stats.avgWinRate >= 0.42
              ? colors.avg
              : colors.bad,
        }}
      >
        {round((stats.roundsWon / stats.roundsPlayed) * 100)} %
      </h1>
      <section className="WinrateContainer">
        {stats.roundsWon} Won
        <div className="WinrateBar">
          <div
            className="WinratePercentage"
            style={{ width: (stats.roundsWon / stats.roundsPlayed) * 150 }}
          ></div>
        </div>
        {stats.roundsLost} Lost
      </section>
      <h2>{stats.roundsPlayed} total rounds played</h2>
    </div>
  );
};

export default WinratePerf;
