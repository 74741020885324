import React from "react";
import "./ScoreboardPlayer.css";
import { useUser } from "../../../features/auth/UserContext";

import agents from "../../../assets/templates/agents.json";
import ranks from "../../../assets/templates/compTiers.json";

const ScoreboardPlayer = ({ player }) => {
  const { user } = useUser();

  const stats = player["stats"];
  const rounds = player["stats"]["roundsPlayed"];
  const headshotPercentage = Math.round(
    (stats["headshots"] / (stats["bodyAndLegShots"] + stats["headshots"])) * 100
  );

  const clutchAttempts = stats["clutches"]["attempts"]["total"];
  const clutchSuccesses = stats["clutches"]["successes"]["total"];
  const clutchPercentage = Math.round(
    (clutchSuccesses / (clutchAttempts === 0 ? 1 : clutchAttempts)) * 100
  );

  const totalCasts = Object.values(stats["abilityCasts"]).reduce(
    (acc, value) => acc + value,
    0
  );
  const avgCasts = Math.round((totalCasts / rounds) * 10) / 10;

  return (
    <div
      className="ScoreboardPlayer"
      style={
        player.puuid === user.puuid
          ? {
              backgroundColor: "rgba(240, 203, 116, .7)",
            }
          : null
      }
    >
      <section className="Player">
        <div>
          <img
            src={agents[player.characterId].displayIcon}
            alt="VALORANT Agent"
            width={50}
            height={50}
          />
        </div>

        <div>
          <h3>{player["gameName"]}</h3>
        </div>
      </section>

      <div className="Rank">
        <img
          src={ranks[player["competitiveTier"]]["smallIcon"]}
          alt="VALORANT Rank Icon"
          height={30}
        />
      </div>
      <div className="ACS">{Math.round(stats["score"] / rounds)}</div>
      <div className="K">{stats["kills"]}</div>
      <div className="D">{stats["deaths"]}</div>
      <div className="A">{stats["assists"]}</div>
      <div className="K/D">
        {Math.round((stats["kills"] / stats["deaths"]) * 10) / 10}
      </div>
      <div className="ADR">{Math.round(stats["damage"] / rounds)}</div>
      <div className="HS%">{headshotPercentage}%</div>
      <div className="KAST%">
        {Math.round((stats["kastRounds"].length / stats["roundsPlayed"]) * 100)}
        %
      </div>

      <div className="Trade Kills">{stats["tradedTeammate"].length}</div>
      <div className="Deaths Traded">{stats["wasTraded"].length}</div>
      <div className="FK">{stats["firstKills"].length}</div>
      <div className="FD">{stats["firstDeaths"].length}</div>
      <div className="Clutches">
        {clutchSuccesses}/{clutchAttempts} ({clutchPercentage}%)
      </div>
      <div className="Casts">{avgCasts}</div>
    </div>
  );
};

export default ScoreboardPlayer;
