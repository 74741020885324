import React, { useState } from "react";
import "./MatchItem.css";

import agents from "../../assets/templates/agents.json";
import maps from "../../assets/templates/maps.json";

const MatchItem = ({ match }) => {
  const [loaded, setLoaded] = useState(false);

  const formatDate = (milliseconds) => {
    const date = new Date(milliseconds);
    const month = date.getMonth() + 1; // Add 1 to get the correct month (0-indexed)
    const day = date.getDate();
    const year = date.getFullYear();

    // Pad the month and day with leading zeros if necessary
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    return `${formattedMonth}/${formattedDay}/${year}`;
  };

  const matchHeight = 50;

  return (
    <li key={match.matchId}>
      <div className="MatchItem">
        <div
          className="WinMarker ItemContainer"
          style={{
            backgroundColor: match.teamWon ? "#30c464" : "#ff4f4f",
          }}
        ></div>
        <div className="Map ItemContainer">
          <img
            src={agents[match.characterId].displayIcon}
            alt="VALORANT Agent Icon"
            width={matchHeight}
            height={matchHeight}
            className="AgentIcon"
          />
          <img
            src={maps[match.mapId].listView}
            alt="VALORANT Map Splash Screen"
            height={matchHeight}
            className="MapSplash"
            style={{ display: loaded ? "flex" : "none" }}
            onLoad={() => setLoaded(true)}
          />
          <h1>{match ? maps[match.mapId].displayName : "null"}</h1>
        </div>

        <div className="Date ItemContainer">
          {formatDate(match.gameStartMillis)}
        </div>

        <div
          className="Score ItemContainer"
          style={{ color: match.teamWon ? "#30c464" : "#ff4f4f" }}
        >
          {match.score.redScore} : {match.score.blueScore}
        </div>

        <div className="ACS ItemContainer">{Math.round(match.acs)}</div>

        <div className="KDA ItemContainer">
          {match.kda.kills}/{match.kda.assists}/{match.kda.deaths}
        </div>

        <div className="ItemContainer">{Math.round(match.KAST * 100)}%</div>
        <div className="Impact ItemContainer">
          {match.openingDuels.totalFirstKills}:
          {match.openingDuels.totalFirstDeaths}
        </div>
      </div>
    </li>
  );
};

export default MatchItem;
