import React from "react";
import "./ReplayTeams.css";

import ReplayTeam from "./ReplayTeam";

const ReplayTeams = ({ match, selectedRound }) => {
  return (
    <section className="ReplayTeams">
      {match["teams"].map((team) => (
        <ReplayTeam
          team={team}
          match={match}
          selectedRound={selectedRound}
          key={team["teamId"]}
        />
      ))}
    </section>
  );
};

export default ReplayTeams;
