import React, { useState, useEffect } from "react";
import "./Agents.css";

import { fetchWithToken } from "../../features/api/api";

import Sidebar from "../../components/Sidebar/Sidebar";
import PageTitle from "../../components/Formats/PageTitle";
import AgentPreview from "./AgentPreview";
import Loading from "../../components/Loading";

const Agents = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await fetchWithToken("/stats/agents");
        setStats(data);
      } catch (error) {
        // Handle or display error
      }
    };

    fetchStats();
  }, []);

  const legendKeys = [
    "Agent",
    "Name",
    "Matches",
    "Win %",
    "Kills",
    "Deaths",
    "Assists",
    "ACS",
    "ADR",
    "HS %",
    "KAST %",
  ];

  let agents = [];

  for (var k in stats) {
    if (
      stats.hasOwnProperty(k) &&
      k !== "_id" &&
      k !== "puuid" &&
      k !== "createdAt" &&
      k !== "updatedAt" &&
      k !== "__v"
    ) {
      // Adding each object (or value) to the array
      agents.push(stats[k]);
    }
  }

  agents.sort((a, b) => b.timesPlayed - a.timesPlayed);

  return (
    <main className="Agents Page">
      <Sidebar />
      {/* <p>{stats ? JSON.stringify(stats) : ""}</p> */}

      <section className="PageContent">
        <PageTitle title="Agents" subtitle="Agent specific statistics" />

        {stats ? (
          <main className="AgentsContent">
            <div className="AgentsLegend">
              {legendKeys.map((legendKey) => (
                <div className="LegendKey" key={legendKey}>
                  <p>{legendKey}</p>
                </div>
              ))}
            </div>

            {agents.map((agent) => (
              <AgentPreview agent={agent} key={agent._id} />
            ))}
          </main>
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default Agents;
