import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Maps.css";

import { fetchWithToken } from "../../features/api/api";

import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar/Sidebar";
import PageTitle from "../../components/Formats/PageTitle";

import maps from "../../assets/templates/maps.json";
import MapOverview from "./MapOverview";

const Maps = () => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await fetchWithToken("/stats/maps");
        setStats(data);
      } catch (error) {
        // Handle or display error
      }
    };

    fetchStats();
  }, []);

  let allMaps = [];

  for (var k in stats) {
    if (
      stats.hasOwnProperty(k) &&
      k !== "_id" &&
      k !== "puuid" &&
      k !== "createdAt" &&
      k !== "updatedAt" &&
      k !== "__v"
    ) {
      // Adding each object (or value) to the array
      allMaps.push(stats[k]);
    }
  }

  allMaps.sort((a, b) => b.timesPlayed - a.timesPlayed);

  return (
    <main className="Maps Page">
      <Sidebar />
      {/* <p>{stats ? JSON.stringify(stats) : ""}</p> */}

      <section className="PageContent">
        <PageTitle title="Maps" subtitle="All your map specific statistics" />

        {stats ? (
          allMaps.map((map) => (
            <Link
              to={`/map/${maps[map.mapId].displayName}`}
              className="MapItemLink"
              key={map.mapId}
            >
              <MapOverview map={map} />
            </Link>
          ))
        ) : (
          <Loading />
        )}
      </section>
    </main>
  );
};

export default Maps;
